export const HEADER_ITEM = {
  login: {
    title: {
      vi: 'Đăng nhập',
      en: 'Login',
    },
    icon: '',
    order: '',
    path: '/login/',
    ref: 'login'
  },
  register: {
    title: {
      vi: 'Đăng ký',
      en: 'Regíter',
    },
    icon: '',
    order: '',
    path: '/register/',
    ref: 'register'
  },
  home: {
    title: {
      vi: 'Trang chủ',
      en: 'Home',
    },
    icon: '',
    order: '',
    path: '/',
    ref: 'home'
  },
  news: {
    title: {
      vi: 'Tin tức',
      en: 'News',
    },
    icon: '',
    order: '',
    path: '/news',
    ref: 'news'
  },
  manga: {
    title: {
      vi: 'Manga',
      en: 'Manga',
    },
    icon: '',
    order: '',
    path: '/manga/',
    ref: 'manga'
  },
  movie: {
    title: {
      vi: 'Phim',
      en: 'Movie',
    },
    icon: '',
    order: '',
    path: '/movie/',
    ref: 'movie'
  },
  chat: {
    title: {
      vi: 'Chat',
      en: 'Chat',
    },
    icon: '',
    order: '',
    path: '/chat/',
    ref: 'chat'
  },
};
