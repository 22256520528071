var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"detailFilm-wrapper"},[_c('div',{staticClass:"screen-watching bgc-black"},[(_vm.isLoading)?_c('div',{staticClass:"loading-screen-watching"}):_vm._e(),(!_vm.isLoading)?_c('div',{attrs:{"id":"container"}},[_c('video',{attrs:{"id":"player","playsinline":"","controls":"","data-poster":"/path/to/poster.jpg"}},[_c('source',{attrs:{"src":_vm.film?.video.url || '../../../assets/video/dasaitulucdau.mp4',"type":"video/mp4"}}),_c('track',{attrs:{"kind":"captions","label":"English captions","src":"/path/to/captions.vtt","srclang":"en","default":""}})])]):_vm._e()]),_c('div',{staticClass:"film-info"},[_c('div',{staticClass:"film-box-1"},[(_vm.isLoading)?_c('Loader',{attrs:{"isImg":""}}):_vm._e(),(!_vm.isLoading)?_c('img',{staticClass:"film-img",attrs:{"src":_vm.film?.image?.url,"alt":_vm.film.name,"isImg":""}}):_vm._e(),_c('div',{staticClass:"film-info-des row"},[(_vm.isLoading)?_c('Loader',{attrs:{"isDes":""}}):_vm._e(),(!_vm.isLoading)?_c('table',[_c('tr',[_c('td',{staticClass:"col-md-4"},[_vm._v("Năm phát hành")]),_c('td',{staticClass:"col-md-8"},[_vm._v(_vm._s(_vm.film.createdAt))])]),_c('tr',[_c('td',{staticClass:"col-md-4"},[_vm._v("Lượt thích")]),_c('td',{staticClass:"col-md-8"},[_vm._v(_vm._s(_vm.reacts?.length))])]),_c('tr',[_c('div',{staticClass:"col-md-4"},[(_vm.isAuthenticated)?_c('button',{staticClass:"btn-like bgc-blue",on:{"click":function($event){return _vm.likeFilms(_vm.film.slug)}}},[_c('svg',{class:{
                    'fill-red': _vm.reacts.includes(_vm.userInfo?._id),
                    'fill-white': !_vm.reacts.includes(_vm.userInfo?._id),
                  },attrs:{"id":"heart-Like","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"}})]),_c('span',{staticClass:"cl-white"},[_vm._v(" Like ")])]):_vm._e(),_c('router-link',{attrs:{"to":"/login/"}},[(!_vm.isAuthenticated)?_c('button',{staticClass:"btn-like bgc-blue"},[_c('svg',{class:{
                      'fill-red': _vm.reacts.includes(_vm.userInfo?._id),
                      'fill-white': !_vm.reacts.includes(_vm.userInfo?._id),
                    },attrs:{"id":"heart-Like","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"}})]),_c('span',{staticClass:"cl-white"},[_vm._v(" Like ")])]):_vm._e()])],1)])]):_vm._e()],1)],1),_c('div',{staticClass:"film-box-2"},[(_vm.isLoading)?_c('Loader',{attrs:{"isDes":""}}):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"film-title"},[_vm._v(_vm._s(_vm.film.name))]):_vm._e(),(!_vm.isLoading)?_c('div',[_vm._v(" "+_vm._s(_vm.film.description)+" ")]):_vm._e()],1)]),_c('div',{staticClass:"film-cmt"},[_c('div',{staticClass:"write-cmt-box"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.cmt.content),expression:"cmt.content"}],staticClass:"input-cmt",attrs:{"type":"text","placeholder":"Hãy chia sẻ cảm nghĩ về bài viết","value":""},domProps:{"value":(_vm.cmt.content)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.cmt, "content", $event.target.value)}}}),_c('button',{staticClass:"btn-send-cmt bgc-blue_3 cl-white",on:{"click":_vm.sendCmt}},[_vm._v("Gửi")])]),(_vm.isAuthenticated)?_c('div',{staticClass:"cmt-films-box"},_vm._l((_vm.film?.comments),function(comment,key){return _c('div',[_c('Commentfilm',{attrs:{"data":comment,"sourceId":_vm.id,"flag":"film"}}),(comment?.replies)?_c('div',[_c('div',{staticClass:"cmt-rep"},_vm._l((comment.replies),function(reply){return _c('div',[_c('Commentfilm',{attrs:{"data":reply}})],1)}),0)]):_vm._e()],1)}),0):_vm._e(),(!_vm.isAuthenticated)?_c('div',[_vm._v("Hãy đăng nhập để đọc được bình luận")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }