import { HEADER_ITEM } from '../../constants';
import { mapState } from 'vuex';
import { store } from '../../store/vuex';
import { AuthService } from '@/services';

export default {
  name: 'Header',
  computed: {
    ...mapState(['userInfo', 'isAuthenticated', 'isPage']),
  },
  mounted() {
    // store.commit('setIsPage', 'home');
    console.log(this.isPage);
  },
  methods: {
    async logOut() {
      const [result, error] = await AuthService.logout();
      console.log(result);
      if (result) {
        this.$store.commit('setUserInfo', null);
        this.$store.commit('setIsAuthenticated', false);
      }
      //refresh page
      window.location.reload();
    },
    async fetchMe() {
      const [result, eror] = await UserService.me();
      if (result) {
        this.$store.commit('setUserInfo', result);
        this.$store.commit('setIsAuthenticated', true);
      } else {
        this.$store.commit('setUserInfo', null);
        this.$store.commit('setIsAuthenticated', false);
      }
    },
    showtoolTip() {
      const toolTip = document.getElementById('toolTip-user');
      const caretDown = document.getElementById('caret-down');
      const caretUp = document.getElementById('caret-up');
      if (toolTip.classList.length === 2) {
        toolTip.classList.add('showToolTip');
        toolTip.style.top = '60px';
        caretDown.style.display = 'none';
        caretUp.classList.remove('caret-hidden');
      } else {
        toolTip.style.top = '-100px';
        caretDown.style.display = 'inline';
        caretUp.classList.add('caret-hidden');
        toolTip.classList.remove('showToolTip');
      }
    },
    showBoxNotification() {},
    hiddenBoxNotification() {},
    onHomeBtnClick: function () {
      // this.$refs.home.classList.add('active');
      // this.$refs.news.classList.remove('active');
      // this.$refs.manga.classList.remove('active');
      // this.$refs.movie.classList.remove('active');
      // this.$refs.chat.classList.remove('active');
      // mark = 'home';
      // console.log(mark);
      store.commit('setIsPage', 'home');
    },
    onNewsBtnClick: function () {
      // this.$refs.news.classList.add('active');
      // this.$refs.home.classList.remove('active');
      // this.$refs.manga.classList.remove('active');
      // this.$refs.movie.classList.remove('active');
      // this.$refs.chat.classList.remove('active');
      // mark = 'home';
      // console.log(mark);
      store.commit('setIsPage', 'articles');
    },
    onMangaBtnClick: function () {
      // this.$refs.manga.classList.add('active');
      // this.$refs.news.classList.remove('active');
      // this.$refs.home.classList.remove('active');
      // this.$refs.movie.classList.remove('active');
      // this.$refs.chat.classList.remove('active');
      // mark = 'manga';
      // console.log(mark);
      store.commit('setIsPage', 'manga');
    },
    onMovieBtnClick: function () {
      // this.$refs.movie.classList.add('active');
      // this.$refs.news.classList.remove('active');
      // this.$refs.manga.classList.remove('active');
      // this.$refs.home.classList.remove('active');
      // this.$refs.chat.classList.remove('active');
      // mark = 'movie';
      // console.log(mark);
      store.commit('setIsPage', 'movie');
    },
    onChatBtnClick: function () {
      // this.$refs.chat.classList.add('active');
      // this.$refs.news.classList.remove('active');
      // this.$refs.movie.classList.remove('active');
      // this.$refs.manga.classList.remove('active');
      // this.$refs.home.classList.remove('active');
      // mark = 'chat';
      // console.log(mark);
      store.commit('setIsPage', 'chat');
    },
  },
  data() {
    return {
      lang: 'vi',
      HEADER_ITEM,
      isShowNotifi: false,
    };
  },
};
