import { render, staticRenderFns } from "./dropzone_story.vue?vue&type=template&id=4e6dbd13&scoped=true&"
import script from "./dropzone_story.vue?vue&type=script&lang=js&"
export * from "./dropzone_story.vue?vue&type=script&lang=js&"
import style0 from "./dropzone_story.vue?vue&type=style&index=0&id=4e6dbd13&prod&scoped=true&lang=scss&"
import style1 from "./dropzone_story.vue?vue&type=style&index=1&id=4e6dbd13&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e6dbd13",
  null
  
)

export default component.exports