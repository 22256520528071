export const HOME_ITEM = {
  like: {
    title: {
      vi: 'Lượt thích',
      en: 'Like',
    },
  },
  likeBtn: {
    title: {
      vi: 'Thích',
      en:'Like'
    }
  },
  comment: {
    title: {
      vi: 'Bình luận',
      en: 'Comments',
    },
  },
  relay: {
    title: {
      vi: 'Trả lời',
      en: 'Replay',
    },
  },
  send: {
    title: {
      vi: 'Gửi',
      en:'Send'
    }
  }
};
