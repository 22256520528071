import FilterFilm from '../../components/Watching/FilterFilm/index.vue';
import CardFilm from '../../components/CardFilm/index.vue';
import Loader from '../../components/Loader/index.vue';
import Skeleton from '../../components/Loader/skeleton.vue';
import { FilmServices } from '@/services';
import basePagination from '@/template/BasePagination.vue';
export default {
  components: {
    FilterFilm,
    CardFilm,
    Loader,
    Skeleton,
    basePagination,
  },
  data() {
    return {
      items: [],
      pageOfItems: 1,
      page: 1,
      per_page: 15,
      total_count: 0,
      searchString: '',
      isLoading: true,
    };
  },
  async mounted() {
    const [
      { items = [], total_count } = {
        items: [],
      },
      error,
    ] = await FilmServices.get({
      page: this.page,
      per_page: this.per_page,
    });
    this.items = items;
    this.total_count = total_count;
    console.log(items);
    this.isLoading = false;
  },
  methods: {
    async onChangePage(page) {
      this.page = page;
      const [
        { items = [], total_count } = {
          items: [],
        },
        error,
      ] = await FilmServices.get({
        page: this.page,
        per_page: this.per_page,
      });
      this.items = items;
      this.total_count = total_count;
    },
    async searchFilm(searchString) {
      // this.isLoading= false
      const [
        { items = [], total_count } = {
          items: [],
        },
        error,
      ] = await FilmServices.search({
        q: searchString,
      });
      this.items = items;
      this.total_count = total_count;
      console.log(items);
    },
  },
};
